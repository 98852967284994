import React, { useState, useEffect } from 'react';
import './App.css';
import desktopImage from './images/charl-folscher-1300233-unsplash.jpg';
import mobileImage from './images/charl-folscher-1300233-unsplash.jpg';
import { Nav } from 'react-bootstrap';

const App = () => {
    const [windowWidth, setWindowWidth ] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    });

    return (
        <div className="App" style={{backgroundImage: `url(${imageUrl})` }}>
            <div className="App-content">
              <h1>ajayvaswani.com</h1>
              <p>This site is under construction.
                - Life is good!!</p>
            </div>
        </div>
    );

};

export default App;
